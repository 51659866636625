@import "../../../Sass/main.scss";

@media (max-width: 450px) {
  .compModalOptions {
    width: 358px;
  }
}

@media (min-width: 451px) {
  .compModalOptions {
    width: 450px;
  }
}

.compModalOptions {
  background-color: $text-5;
  border-top: 6px solid $Standard_Default;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 100vh;
  padding: 0px 24px 32px 24px;
  position: fixed;

  h2 {
    color: $text3;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 24px;
  }

  section {
    align-items: stretch;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 12px;

    .item {
      align-items: center;
      align-self: stretch;
      cursor: pointer;
      display: flex;
      flex: 0 0 30%;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      padding: 12px;
      text-align: center;

      .informationArea {
        align-items: center;
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        gap: 4px;

        p {
          color: $text1;
          font-family: "Montserrat-Regular";
          font-size: 12px;
          font-style: normal;
          line-height: 20px;
          margin: 0;
          text-align: center;
          width: 100%;
        }

        .title {
          font-weight: 700;
        }

        .description {
          font-weight: 400;
        }
      }

      .icon {
        height: 18px;
        width: 18px;

        path {
          fill: $background-2;
        }
      }
    }
  }
}
