@import "../../../Sass/main.scss";

.compClosedAnswer {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 8px;
}
