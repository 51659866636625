@import "../../../Sass/main.scss";

.compQuestionConditions {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 8px;
  justify-content: flex-start;
  width: 100%;

  .firstArea {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 4px;

    .optionCondition {
      align-items: center;
      border-radius: 4px;
      color: $text2;
      display: flex;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      gap: 8px;
      justify-content: center;
      line-height: 20px;
      padding: 6px 12px;
      min-width: 50px;
    }

    .optionColor {
      border-radius: 2px;
      height: 18px;
      width: 32.4px;
    }
  }

  .secondArea {
    align-items: center;
    align-self: stretch;
    display: flex;
    color: $text3;
    gap: 18px;
    padding: 0px 12px;
    font-family: "Montserrat-Regular";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;

    input {
      background-color: rgba($color: #000000, $alpha: 0);
    }
  }
}
