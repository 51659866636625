@import "../../../Sass/main";

.comModalMessageCenter {
  background-color: $text-5;
  font-family: "Montserrat-Regular";
  font-style: normal;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  .divNoMessages {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 20px;
    height: 90vh;
    justify-content: center;

    .notificationIcon {
      height: 112px;
      width: 112px;
    }

    p {
      color: $text-1;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
      text-align: center;
      white-space: pre;
    }
  }
}
