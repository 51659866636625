@import "../../../Sass/main.scss";

.compActions {
  background-color: $text-5;
  position: fixed;
  top: 0;
  width: 100%;

  .defaultTop {
    display: flex;
    height: 56px;
    padding: 6px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;

    .letteringHazo {
      align-self: stretch;
      color: $Standard_Default;
      font-family: "Montserrat-Regular";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 42px;
      width: 74px;
    }

    .iconsArea {
      align-items: center;
      display: flex;
      gap: 32px;

      .notificationArea {
        position: relative;
        width: 25px;

        .numberNotification {
          align-items: center;
          background-color: $Error_Standard_Default;
          border-radius: 50%;
          display: flex;
          height: 16px;
          justify-content: center;
          position: absolute;
          right: 0;
          width: 16px;
          z-index: 1;

          span {
            color: $text-5;
            font-family: "Montserrat-Regular";
            font-size: 8px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .icon {
        cursor: pointer;
        height: 18px;
        width: 18px;
        path {
          fill: $Standard_Default;
        }
      }
    }
  }

  .bottomsArea {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;

    .firstArea {
      display: flex;
      gap: 8px;

      .iconArrowDown {
        height: 12px;
        width: 12px;
        path {
          fill: $Standard_Default;
        }
      }

      p {
        color: $Standard_Default;
        font-family: "Montserrat-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin: 0;
      }
    }

    .secondArea {
      display: flex;
      gap: 32px;

      .iconGray {
        cursor: pointer;
        height: 18px;
        width: 18px;
        path {
          fill: $background-2;
        }
      }

      .iconGrayDisabled {
        cursor: normal;
        height: 18px;
        width: 18px;
        path {
          fill: $background-4;
        }
      }

      .iconTrash {
        cursor: pointer;
        height: 18px;
        width: 18px;
        path {
          fill: $Error_Standard_Default;
        }
      }

      .iconSearch {
        cursor: pointer;
        height: 18px;
        width: 18px;
        path {
          fill: $Standard_Default;
        }
      }
    }
  }
}
