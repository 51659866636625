@import "../../Sass/main.scss";

.compLogic {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  font-family: "Montserrat-Regular";
  font-style: normal;
  gap: 6px;

  .iconDefault {
    height: 16px;
    width: 16px;
  }

  .starIcon {
    path {
      fill: $Warning_Dark_0;
    }
  }

  .likeIcon {
    path {
      fill: $Success_Dark_0;
    }
  }

  .dislikeIcon {
    path {
      fill: $Error_Standard_Default;
    }
  }

  .robotIconDefault {
    height: 32px;
    margin-top: 38px;
    width: 32px;

    &.text {
      margin-top: 4px;
    }
  }

  .questionArea {
    width: 100%;

    .icon {
      height: 20px;
      width: 20px;
      path {
        fill: $Standard_Default;
      }
    }

    .mainArea {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 6px;
      flex: 1 0 0;

      .title {
        align-items: center;
        color: $text-1;
        display: flex;
        font-family: "Montserrat-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        gap: 8px;
        line-height: 20px;
        margin: 0;
        padding: 6px 2px;
      }

      .mainSpace {
        -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
        align-items: center;
        align-self: stretch;
        display: flex;
        background: $background-5;
        border-radius: 0px 8px 8px 8px;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        padding: 8px;
        position: relative;

        .mainText {
          align-self: flex-start;
          margin: 0;
        }
      }

      .mainOptionsArea {
        align-items: center;
        display: flex;
        flex-direction: column;

        .imageVideoContainer {
          max-width: 425px;
          max-height: 425px;
          overflow: hidden;

          img {
            height: 100%;
            object-fit: contain;
            width: 100%;
          }
        }

        .showOptionsButton {
          display: flex;
          padding: 4px 18px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          color: $text-5;
          font-family: "Montserrat-Regular";
          font-size: 12px;
          font-style: normal;
          font-weight: 700;

          img {
            height: 20px;
            margin: 2px;
            object-fit: contain;
            width: 20px;
          }
        }

        .sectionOption {
          display: flex;
          flex-direction: column;
          padding: 15px 0;
          width: 100%;

          svg {
            align-self: center;
            color: $color121;
            height: 33px;
            object-fit: contain;
          }

          .option {
            /* border: 2px solid $color121; */
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 5px;
            width: 100%;

            & > div {
              margin: 5px;
            }
          }

          .show-next {
            align-items: center;
            align-self: center;
            border: 1px solid $background-1;
            border-radius: 8px;
            color: $text3;
            display: flex;
            font-size: 14px;
            font-weight: 400;
            gap: 8px;
            justify-content: center;
            line-height: 20px;
            padding: 8px 18px;
            width: 300px;
          }
        }
      }
    }
  }
}


