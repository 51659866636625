@import "../../../Sass/main";

.compModalUpgrade {
  background-color: $text-5;
  font-family: "Montserrat-Regular";
  font-style: normal;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  main {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px 0 24px 0;

    .text {
      color: $text0;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin: 0;
      text-align: center;
      white-space: pre;
    }

    .contentCards {
      width: 100%;

      .swiper {
        padding: 40px 0 10px 0;
        position: relative;
      }

      .swiper-pagination {
        bottom: 720px;
        position: absolute;

        .swiper-pagination-bullet {
          background-color: $background-4;
          padding: 5px;
        }

        .swiper-pagination-bullet-active {
          background-color: $Standard_Default;
        }
      }

      .cardUpgrade {
        align-items: center;
        display: flex;
        justify-content: center;
      }
    }
  }
}
