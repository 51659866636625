@import "../../Sass/main";

.compInteractiveList {
  align-items: center;
  background-color: $text-5;
  display: flex;
  font-family: "Montserrat-Regular";
  font-style: normal;
  justify-content: space-between;
  padding: 16px;
  width: 390px;

  .content {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    gap: 8px;
    width: 281px;

    .text {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      .title {
        color: var(--text-3, $text3);
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
      }

      .description {
        color: var(--text-1, $text1);
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
      }
    }

    .circle {
      align-items: center;
      background-color: $background-5;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      height: 36px;
      width: 36px;
    }
  }

  .iconBlue {
    path {fill: $Standard_Default};
  }

  .iconRed {
    path {fill: $Error_Standard_Default};
  }

  &:hover {
    background-color: $background-5;

    .circle {
      background-color: $text-5;
    }
  }
}
