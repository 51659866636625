@import "../../Sass/main";

.headerPlans {
  height: 103px;
  margin: 0 auto;
  width: 390px;
}

.containerMainPlans {
  align-items: flex-start;
  background: $text-5;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 1022px;
  margin: 0 auto;
  // overflow-x: hidden;
  padding: 6px 16px;
  width: 390px;

  .containerTextPlans {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .alertProfile {
      position: absolute;
      justify-content: center;
      top: 10px;
    }

    .statusPlans {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      gap: 16px;
      position: relative;

      .iconStatusPlans {
        path{fill: $Success_Standard_Default};
        height: 42px;
        width: 42px;
      }

      .h5ContainerPlans {
        color: var(--text-3, $text3);
        font-family: "Montserrat-Regular";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
        margin: 0;
      }

      .imgHazoPlanFree {
        position: absolute;
        right: -220px;
        transform: rotate(-35.459deg);
        top: -30px;
        width: 164px;
        height: 164px;
      }

      .imgHazoPlanSigned {
        position: absolute;
        right: -140px;
        top: -60px;
        width: 164px;
      }
    }

    .pContainerPlans {
      align-self: stretch;
      color: var(--text-0, $text0);
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
      width: 260px;
    }
  }
}
