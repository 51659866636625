@import "../../Sass/main";

.compCardPlan {
  align-items: center;
  background-color: $text-5;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-style: normal;
  gap: 32px;
  height: 690px;
  overflow: hidden;
  padding: 24px;
  position: relative;
  width: 341px;

  p, span {
    margin: 0;
  }

  .tagPremium {
    align-items: center;
    background-color: $Standard_Default;
    bottom: 635px;
    color: $text-5;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    gap: 10px;
    justify-content: center;
    left: -32px;
    line-height: 24px;
    padding: 6px 10px;
    position: absolute;
    transform: rotate(-42.401deg);
    width: 151px;
  }

  .header {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .title {
      font-weight: 700;
      text-transform: uppercase;
    }

    .description {
      color: $gray3;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .icon {
    height: 100px;
    width: 100px;

    path {
      fill: $background0;
    }
  }

  .wrapperCardPlan {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 4px;

    span {
      color: $gray3;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-transform: uppercase;
    }

    .price {
      align-items: center;
      display: flex;
      gap: 4px;

      .symbol {
        font-weight: 600;
      }

      .value {
        font-size: 44px;
        font-weight: 700;
        line-height: 72px;
      }
    }
  }

  .areaPaypal {
    p {
      color: $gray3;
      font-size: 13px;
      font-weight: 400;
      text-align: center;
    }
  }

  .button {
    width: 100%;
  }

  .text {
    color: $gray2;
    font-weight: 600;
  }

  .contentBenefits {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .benefit {
      align-items: center;
      align-self: stretch;
      display: flex;
      gap: 8px;

      .iconCheck {
        flex-shrink: 0;
        height: 16px;
        width: 16px;

        path {
          fill: $Success_Standard_Default;
        }

        &.disabled {
          path {
            fill: $background-4;
          }
        }
      }

      .benefitText {
        color: $gray3;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;

        &.disabled {
          color: $background-4;
        }
      }
    }
  }

  .header
  .title,
  .text,
  .benefitText,
  .price
  .symbol {
    font-size: 16px;
    line-height: 28px;
  }
}
