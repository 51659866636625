@import "../../../Sass/main.scss";

.compCenterNoIcon {
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding: 6px 16px;
  width: 100%;

  .left {
    align-items: center;
    display: flex;

    span {
      color: $Standard_Default;
      cursor: pointer;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
    }
  }

  .middle {
    color: $Standard_Default;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
  }

  .right {
    align-items: center;
    display: flex;
    gap: 32px;
    justify-content: flex-end;

    .default {
      color: $Standard_Default;
    }

    .red {
      color: $Error_Standard_Default;
    }

    span {
      cursor: pointer;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      text-decoration-line: underline;
    }
  }

  .icon {
    cursor: pointer;
    height: 18px;
    width: 18px;
    path {
      fill: $Standard_Default;
    }
  }
}
