@import "../../../Sass/main";

.compModalConfigLink {
  background-color: $text-5;
  font-family: "Montserrat-Regular";
  font-style: normal;
  height: 100vh;
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  z-index: 1;

  main {
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    overflow-y: scroll;

    .cardVariables {
      display: flex;
      padding: 12px 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      border-radius: 8px;
      border: 1px solid $background-4;
      width: 358px;

      .title {
        color: $text3;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
      }

      .toogle {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          color: $gray2;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          margin: 0;
        }
      }
    }
  }


}
