@import "../../../Sass/main";

.compModalProjectDetails {
  display: flex;

  background-color: $text-5;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-style: normal;
  height: 100%;
  justify-content: center;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  .inputEdit {
    width: 100%;

    span {
      color: $text0;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
      width: 100%;
    }
  }

  .icon {
    cursor: pointer;
    height: 18px;
    width: 18px;

    path {
      fill: $Standard_Default;
    }

    &.trash {
      path {
        fill: $Error_Standard_Default;
      }
    }
  }

  header {
    align-items: center;
    display: flex;
    height: 56px;
    padding: 6px 16px;
    position: absolute;
    top: 0;
    width: 100%;

    .title {
      color: $Standard_Default;
      font-weight: 700;
      line-height: 28px;
      font-size: 16px;
      margin: 0;
      text-align: center;
      width: 100%;
    }

    .icons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 32px;
    }
  }

  .wrapperCards {
    display: flex;
    justify-content: center;
    padding: 12px 16px 24px 16px;
    margin-top: 56px;
    gap: 16px;

    .cards {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      align-self: stretch;

      .iconCard {
        path {
          fill: $text3;
        }
      }
    }
  }
}
