@import "../../../Sass/main.scss";

.compMultipleChoice {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 8px;

  hr {
    background: #f5f5f5;
    height: 1px;
    margin: 0;
    width: 100%;
  }

  .configurationOption {
    color: $text3;
    font-family: "Montserrat-Regular";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
  }
}
