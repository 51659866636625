@import "../../../Sass/main.scss";

.scaleArea {
  align-items: center;
  align-self: stretch;
  color: $text3;
  display: flex;
  font-family: "Montserrat-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  gap: 8px;
  line-height: 20px;
  padding: 0px 2px;

  .optionScale {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 8px;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
      text-align: center;
    }

    input {
      background-color: rgba($color: #000000, $alpha: 0);
    }

    .boxNumber {
      color: $light;
      font-size: 14px;
      height: 45px;
      margin: 0 5px;
      text-align: center;
      width: 45px;
    }
  }
}
