@import "../../Sass/main.scss";

.compDatabase {
  background-color: #e7e7e7;
  width: 100%;

  .containerData {
    width: 100%;
  }

  .content {
    background-color: #fff;
    border-radius: 5px;
    min-height: 100vh;
    padding: 10px 10px 0 10px;
    overflow: hidden;
  }

  .content-without-plan {
    background-color: #fff;
    border-radius: 5px;
    min-height: 100vh;
    margin-top: 95px;
    padding: 10px 10px 0 10px;
    overflow: hidden;
  }

  .integration {
    background-color: #fff;
    border-radius: 10px;
    height: 100%;
    margin-bottom: 10px;
    padding: 10px 10px 10px 10px;
  }

  .integration-elements {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: auto;
    width: 100%;
  }

  .integration-texts {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .integration-switch {
    display: flex;
    margin-left: auto;
    margin-right: 0;
  }

  .message {
    background: #e7e7e7;
    color: $danger-color;
    font-weight: 500;
    margin: 10px 0;
    padding: 5px 0;
    text-align: center;
  }

  .control-area {
    display: flex;
    margin: 15px 0;
    width: 100%;

    button {
      align-items: center;
      border: none;
      display: flex;
      justify-content: center;
    }

    .btn-edit,
    .btn-delete {
      background: $gray-default;
      border-radius: 5px;
      font-size: 23px;
    }

    .btn-delete {
      margin: 0 10px;
    }

    .btn-save {
      background: $gray-default;
      border-radius: 5px;
      margin-left: 10px;
      padding: 5px 15px;
    }

    input {
      flex: 1;
    }
  }

  .upgrade-plan {
    color: $danger-color;
  }

  .table-area {
    margin: 0 auto;
    margin: 2px;
    min-width: 100%;
    overflow-y: scroll;

    table {
      border: 1px solid #ccc;
      min-width: 100%;

      thead {
        th {
          border-bottom: 1px solid $gray-default;
          border-top: 1px solid $gray-default;
          height: 44px;
          padding: 14px 12px;
          margin: 10px;
          text-align: center;
          color: $background-2;
          font-family: "Montserrat-Regular";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }
      }

      td {
        border-bottom: 1px solid $gray-default;
        border-top: 1px solid $gray-default;
        min-width: 200px;
        padding: 10px;
        text-align: center;
        color: $text2;
        font-family: "Montserrat-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .firstTd {
        padding: 16px 0px;
        min-width: 64px;
      }
    }
  }
}
