@import "../../Sass/main.scss";

.compBox {
  align-items: flex-start;
  background: $text-5;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0px;
  position: fixed;

  .option {
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 6px 24px;

    p {
      color: $text3;
      flex: 1 0 0;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }

    .optionDelete {
      color: $Error_Standard_Default;
    }
  }

  .option:hover {
    background: $Light_0;

    p {
      color: $Dark_0;
    }
  }

  .optionSelected {
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 6px 24px;

    p {
      color: $Dark_0;
      flex: 1 0 0;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin: 0;
    }
  }


}
