@import "../../Sass/main";

.headerProfile {
  height: 103px;
  margin: 0 auto;
  width: 390px;
}

.containerMainProfile {
  align-items: flex-start;
  background: $text-5;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 560px;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 6px 16px;
  width: 390px;

  .formMainProfile {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    height: 50%;
    justify-content: space-between;

    .alertProfile {
      position: absolute;
      justify-content: center;
      top: 10px;
      z-index: 3;
    }

    .inputsFormProfile {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
