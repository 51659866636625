@import "../../Sass/main";

.compCardReport {
  align-items: center;
  background-color: $text-5;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-style: normal;
  gap: 20px;
  justify-content: center;
  max-width: 560px;
  padding: 16px;
  width: 100%;

  span {
    margin: 0;
  }

  .header {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;

    .text {
      color: $Success_Dark_1;
      font-weight: 600;

      &.desativated {
        color: $Error_Dark_1;
      }
    }
  }

  .toogle {
    align-items: center;
    display: flex;
    gap: 8px;

    .toogle span {
      color: $gray2;
      font-weight: 400;
    }
  }

  .title {
    align-self: stretch;
    color: $text3;
    font-size: 16px;
    line-height: 28px;
  }

  .line {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: center;

    .item {
      align-items: center;
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      gap: 2px;

      .value {
        font-size: 28px;
        line-height: 25px;
      }

      .name {
        align-self: stretch;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .header .text,
  .toogle span {
    font-size: 12px;
    line-height: 20px;
  }

  .line .item .value,
  .line .item .name {
    color: $text1;
    text-align: center;
  }

  .line .item .value,
  .title {
    font-family: "Montserrat-Medium";
    font-weight: 700;
  }
}
