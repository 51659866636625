@import "../../Sass/main";

.compSwitchPrice {
  display: inline-flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid $background-3;
  font-family: "Montserrat-Regular";
  font-style: normal;
  position: relative;

  .tag {
    background-color: $Success_Dark_0;
    border-radius: 12px;
    display: flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    top: -20px;

    span {
      color: $text-5;
      margin: 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
