@import "../../Sass/main.scss";

.compBottomTabNewLink {
  align-items: center;
  background: $text-5;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  position: fixed;
  width: 100%;

  .icon {
    height: 16px;
    margin-right: 10px;
    width: 16px;

    path {
      fill: $text-5;
    }
  }

  .textArea {
    color: $text3;
    font-family: "Montserrat-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}
