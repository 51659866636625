@import "../../../Sass/main.scss";

@media (max-width: 450px) {
  .compModalEmbed {
    width: 358px;
  }
}

@media (min-width: 451px) {
  .compModalEmbed {
    width: 450px;
  }
}

.alert {
  z-index: 1000;
  display: flex;
  justify-content: center;
}

.wrapper {
  z-index: 999;
}

.compModalEmbed {
  background-color: $text-5;
  border-top: 6px solid $Standard_Default;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 504px;
  padding: 0px 24px 32px 24px;
  position: relative;

  h2 {
    color: $text3;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 24px;
  }

  nav {
    ul {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-bottom: 0;

      li {
        color: $text1;
        font-family: "Montserrat-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .active {
        color: $Standard_Default;
      }
    }
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .description {
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
    }

    .codeArea {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 24px;

      .code {
        align-items: center;
        align-self: stretch;
        background-color: $Light_0;
        border-radius: 12px;
        display: flex;
        font-family: "Montserrat-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        gap: 10px;
        justify-content: center;
        line-height: 20px;
        padding: 12px;
      }

      .information {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
        gap: 8px;

        p {
          color: $Dark_0;
          font-family: "Montserrat-Regular";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          margin: 0;
        }

        .icon {
          height: 18px;
          width: 18px;

          path {
            fill: $Dark_0;
          }
        }
      }

      .buttonsArea {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: flex-end;
      }
    }

    .previewArea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;

      .messagePreviewLeft {
        flex-direction: row;
      }

      .messagePreviewRight {
        flex-direction: row-reverse;
      }

      .messagePreview {
        display: flex;
        gap: 8px;

        .image {
          display: flex;
          height: 48px;
          justify-content: center;
          width: 48px;

          img {
            height: 32px;
            width: 32px;
          }
        }

        .messageArea {
          align-items: center;
          border-radius: 12px;
          background: $text-5;
          box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          flex: 1 0 0;
          gap: 12px;
          padding: 12px;

          p {
            font-family: "Montserrat-Regular";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin: 0;
          }
        }
      }

      p {
        font-family: "Montserrat-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      ul {
        align-self: center;
        display: flex;
        justify-content: center;
        width: 279px;

        li {
          align-items: center;
          background: var(--primary-light-0, #f0f4ff);
          border: 1px solid $Standard_Default;
          color: $Standard_Default;
          cursor: pointer;
          display: flex;
          font-family: "Montserrat-Regular";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          height: 44px;
          justify-content: center;
          line-height: 24px;
          width: 140px;
        }

        .left {
          border-radius: 12px 0px 0px 12px;
        }

        .right {
          border-radius: 0px 12px 12px 0px;
        }
      }
    }
  }
}
