@import "../../Sass/main";

.compTerms {
  align-items: center;
  color: $text2;
  display: flex;
  font-family: "Montserrat-Regular";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 100vh;
  justify-content: center;
  line-height: 20px;
  width: 100vw;
}
