// Primary colors
$Standard_Default: #609ef1;
$Standard_Hover: #84b4f5;
$Light_0: #f0f4ff;
$Light_1: #ccdffc;
$Light_2: #a8c9f8;
$Dark_0: #528bd3;
$Dark_1: #4578b6;
$Dark_2: #376598;
$Dark_3: #29517b;
$Dark_4: #1b3e5d;
$Dark_5: #0e2b40;
$Dark_6: #001822;

// Helper
$Error_Standard_Default: #ff495c;
$Error_Standard_Hover: #ff7381;
$Error_Light_0: #fff0ee;
$Error_Light_1: #ffc6ca;
$Error_Light_2: #ff9da5;
$Error_Dark_0: #e33f52;
$Error_Dark_1: #c63447;
$Error_Dark_2: #aa2a3d;
$Error_Dark_3: #8d1f33;
$Error_Dark_4: #711529;
$Error_Dark_5: #540a1e;
$Error_Dark_6: #380014;
$Success_Standard_Default: #3ddc97;
$Success_Standard_Hover: #6be8b5;
$Success_Light_0: #c7fff0;
$Success_Light_1: #99f3d2;
$Success_Dark_0: #35c485;
$Success_Dark_1: #2eac74;
$Success_Dark_2: #269462;
$Success_Dark_3: #1f7d50;
$Success_Dark_4: #17653e;
$Success_Dark_5: #0f4d2c;
$Success_Dark_6: #08351b;
$Warning_Standard_Default: #fce762;
$Warning_Standard_Hover: #fff796;
$Warning_Dark_0: #e6d258;
$Warning_Dark_1: #d0bd4e;
$Warning_Dark_3: #bba845;
$Warning_Dark_4: #a5933b;
$Warning_Dark_5: #8f7e31;
$Warning_Dark_6: #796827;
$Warning_Dark_7: #63531d;
$Info_Standard_Hover: #3bbae9;
$Info_Standard_Default: #00a7e1;
$Info_Light_0: #ebf4ff;
$Info_Light_1: #b0e1f8;
$Info_Light_2: #76cef0;
$Info_Dark_0: #0093c5;
$Info_Dark_1: #007fa8;
$Info_Dark_2: #006b8c;
$Info_Dark_3: #00566f;
$Info_Dark_4: #004253;
$Info_Dark_5: #002e36;
$Info_Dark_6: #001a1a;

// Back & text
$background-5: #f5f5f5;
$background-4: #d0d0d0;
$background-3: #ababab;
$background-2: #878787;
$background-1: #626262;
$background0: #3d3d3d;
$background1: #333333;
$background2: #292929;
$background3: #1e1e1e;
$background4: #141414;
$background5: #0a0a0a;
$text-5: #ffffff;
$text-4: #e8e8e8;
$text-3: #d1d1d1;
$text-2: #b9b9b9;
$text-1: #a2a2a2;
$text0: #8b8b8b;
$text1: #747474;
$text2: #5d5d5d;
$text3: #464646;
$text4: #2e2e2e;
$text5: #171717;
$gray2: #4f4f4f;
$gray3: #828282;
$gray5: #e0e0e0;
