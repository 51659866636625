@import "../../Sass/main";

.defaultQuestionLayout {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 6px;

  hr {
    margin: 0;
  }

  .iconDefault {
    height: 16px;
    width: 16px;
  }

  .emojiFrownIcon {
    path {
      fill: $Error_Standard_Default;
    }
  }

  .emojiSadIcon {
    path {
      fill: #f2994a;
    }
  }

  .emojiMehIcon {
    path {
      fill: $Warning_Dark_0;
    }
  }

  .emojiSmileIcon {
    path {
      fill: $Success_Standard_Default;
    }
  }

  .emojiLaughIcon {
    path {
      fill: $Success_Dark_1;
    }
  }

  .starIcon {
    path {
      fill: $Warning_Dark_0;
    }
  }

  .likeIcon {
    path {
      fill: $Success_Dark_0;
    }
  }

  .dislikeIcon {
    path {
      fill: $Error_Standard_Default;
    }
  }

  .boxCheck {
    align-items: center;
    background-color: $light;
    border-radius: 50%;
    border: 1px solid $background-3;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 6px;
    width: 16px;
  }

  .boxChecked {
    background-color: $color121-light;
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }

  .robotIconDefault {
    height: 32px;
    margin-top: 38px;
    width: 32px;
  }

  .questionArea {
    width: 100%;
  }

  .mainAreaDefault {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex: 1 0 0;

    .titleDefault {
      align-items: center;
      color: $text-1;
      display: flex;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      gap: 8px;
      line-height: 20px;
      margin: 0;
      padding: 6px 2px;
    }

    .mainSpace {
      -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
      -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.15);
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      background: $background-5;
      border-radius: 0px 8px 8px 8px;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      padding: 8px;
      position: relative;

      .conditionsArea {
        display: flex;
        flex-direction: column;
        gap: 10px;

        span {
          color: $text0;
          font-family: "Montserrat-Regular";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .mainOptionsArea {
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;

        .imageVideoContainer {
          max-width: 425px;
          max-height: 425px;
          overflow: hidden;

          img {
            height: 100%;
            object-fit: contain;
            width: 100%;
          }
        }

        .likeDislikeIcon {
          height: 43px;
          width: 43px;
        }

        .likeDislikeIconEditOff {
          height: 43px;
          margin-bottom: 10px;
          margin-right: 10px;
          width: 43px;
        }

        .showOptionsButton {
          display: flex;
          padding: 4px 18px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          color: var(--text-5, #fff);
          font-family: "Montserrat-Regular";
          font-size: 12px;
          font-style: normal;
          font-weight: 700;

          img {
            height: 20px;
            margin: 2px;
            object-fit: contain;
            width: 20px;
          }
        }
      }
    }

    .mainText {
      color: $text5;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }

    .buttonPresetDefault {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .iconButtonSelectQuestionDefault {
      height: 12px;
      margin-left: 12px;
      width: 12px;

      path {
        fill: $text-5;
      }
    }

    .textareaDefault:focus {
      outline: 0;
    }

    .textareaDefault {
      border: none;
      border-radius: 8px;
      color: $text5;
      flex: 1 0 0;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      font-style: normal;
      line-height: 20px;
      outline: 0px none transparent;
      padding: 6px 6px;
      padding-right: 78px;
      width: 100%;
    }

    .textareaDefault:focus {
      outline: 0;
    }

    .textareaDefaultRed {
      border: 1px solid $Error_Standard_Default;
    }
  }
}
