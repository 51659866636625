@import "../../Sass/main";

.CompCustomization {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;

  main {
    hr {
      margin: 24px;
    }

    .inputHide {
      display: none;
    }

    .imageArea {
      display: flex;
      flex-shrink: 0;
      height: 227px;

      .textBox {
        align-items: center;
        align-self: center;
        background: $text-5;
        border: 1px solid $background-4;
        border-radius: 16px 0px 16px 16px;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
        color: $text0;
        display: flex;
        font-family: "Montserrat-Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        gap: 10px;
        justify-content: center;
        line-height: 24px;
        padding: 16px;
        width: 272.774px;
      }
    }

    .logoAvatarArea {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 24px 16px;

      .imgArea {
        align-items: center;
        border-color: $text-2;
        border-radius: 12px;
        border-style: solid;
        border-width: 1px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 108px;
        padding: 10px;
        justify-content: center;
        width: 182px;

        img {
          height: 48px;
          width: 48px;
        }

        .imgLogo {
          border-radius: 50%;
        }
      }

      .textArea {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 6px;
        justify-content: center;

        p {
          color: $text3;
          font-family: "Montserrat-Regular";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0;
          text-align: center;
        }

        .description {
          color: $text-1;
          font-size: 12px;
        }
      }
    }

    .colorArea {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 24px 16px;

      .showColor {
        border-radius: 10px;
        height: 48px;
        width: 48px;

        input {
          visibility: hidden;
        }
      }

      .textArea {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          color: $text3;
          font-family: "Montserrat-Regular";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0;
          text-align: center;
        }
      }
    }

    .inputsTextArea {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 24px 16px;

      input:focus,
      textarea {
        outline: none !important;
      }

      input,
      textarea {
        ::placeholder {
          color: $text-2;
          opacity: 1;
        }

        ::-ms-input-placeholder {
          color: $text-2;
        }

        border-radius: 12px;
        border: 1px solid $text-2;

        color: $text2;
        font-family: "Montserrat-Regular";
        font-size: 16px;
        font-style: normal;
        line-height: 34px;
        outline: 0;
        text-align: center;
        width: 100%;
      }

      .textArea {
        align-items: center;
        display: flex;
        justify-content: center;

        p {
          color: $text3;
          font-family: "Montserrat-Regular";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
}
