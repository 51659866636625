@import "../../../Sass/main";

.compModalNewInteraction {
  background-color: $background-5;
  font-family: "Montserrat-Regular";
  font-style: normal;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  span {
    margin: 0;
    padding: 0;
  }

  .wrapperDraft {
    overflow: hidden;
  }

  .divEdit {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 32px;
    justify-content: space-between;
    padding: 0 16px 24px 16px;

    textarea {
      border: none;
      color: $text-2;
      font-size: 20px;
      font-weight: 400;
      height: 100%;
      line-height: 34px;
      width: 358px;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $text-2;
      }

      resize: none;
    }

    .buttonFooter {
      margin-top: auto;
    }
  }

  .modalContent {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 32px;
    justify-content: center;
    padding: 0 16px 24px 16px;

    .templates {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      gap: 16px;
      height: auto;

      .title {
        align-self: flex-start;
        color: $text0;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
      }

      .list {
        align-items: flex-start;
        display: flex;
        gap: 10px;
        overflow-x: scroll;
        padding: 10px;
        width: 100%;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
