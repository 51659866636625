@import "../../Sass/main";

$font-large: 16px;
$font-medium: 14px;
$font-small: 12px;

.compCardOtherLink {
  display: flex;
  width: 358px;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid $background-4;

  font-family: "Montserrat-Regular";
  font-style: normal;

  span {
    margin: 0;
  }

  .header {
    position: relative;

    .title {
      color: $text3;
      font-weight: 600;
      line-height: 28px;
      font-size: $font-large;
    }

    .icon {
      cursor: pointer;
      width: 24px;
      height: 24px;

      path {
        fill: $background-2;
      }
    }
  }

  .description {
    color: $gray3;
    line-height: 24px;
    font-size: $font-medium;
  }

  .link {
    color: $gray2;
  }

  .footer {
    span {
      color: $text3;
    }
  }

  .header, .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .description, .link {
    font-weight: 400;
  }

  .link, .footer span {
    line-height: 20px;
    font-size: $font-small;
  }
}
