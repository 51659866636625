@import "../../../Sass/main";

.wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  .compModalDefault {
    align-items: flex-start;
    background-color: $text-5;
    border-radius: 12px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font-family: "Montserrat-Regular";
    font-style: normal;
    gap: 24px;
    padding: 24px;
    width: 450px;

    span {
      margin: 0;
    }

    &.primary {
      border-top: 6px solid $Standard_Default;
    }

    &.cancel {
      border-top: 6px solid $Error_Standard_Default;
    }

    &.success {
      border-top: 6px solid $Success_Standard_Default;
    }

    .title {
      align-self: stretch;
      color: $text3;
      font-size: 18px;
      font-weight: 600;
      line-height: 32px;
    }

    .description {
      color: $text1;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
    }

    .icon {
      height: 20px;
      width: 20px;

      path {
        fill: $Success_Standard_Default;
      }
    }

    .footer {
      align-items: center;
      align-self: stretch;
      display: flex;
      gap: 16px;
      justify-content: flex-end;
      margin: 0;
    }
  }
}

@media (max-width: 768px) {
  .wrapper {
    .compModalDefault {
      width: 358px;
      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
      }

      .description {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
