@import "../../Sass/main";

$font-large: 16px;
$font-medium: 14px;
$font-small: 12px;

.compCardConfigLink {
  align-items: flex-start;
  background-color: $text-5;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-style: normal;
  gap: 16px;
  padding: 12px 16px;
  width: 358px;

  span {
    margin: 0;
  }

  .title {
    color: $text4;
    font-size: $font-large;
    line-height: 28px;
  }

  .content {
    align-items: center;
    align-self: stretch;
    background-color: $background-5;
    border-radius: 8px;
    display: flex;
    gap: 12px;
    padding: 8px 12px;

    .icon {
      height: 48px;
      width: 48px;

      path {
        fill: $background-2;
      }
    }

    .text {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      gap: 2px;

      .description {
        font-weight: 400;
      }

      .subtitle, .description {
        color: $text2;
        font-size: $font-medium;
        line-height: 24px;
      }
    }
  }

  .footer {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 16px;
    justify-content: space-between;

    .footerText {
      color: $text4;
      font-size: $font-small;
      line-height: 20px;
    }
  }

  .title, .subtitle {
    font-weight: 600;
  }
}

@media (max-width: 768px) {
  .compCardConfigLink {
    .title {
      font-size: $font-medium;
      line-height: 24px;
    }

    .content .text .subtitle, .content .text .description, .footer span {
      font-size: $font-small;
      line-height: 20px;
    }
  }

}
