$buttonChatbotDefaultColor: #606c88;
$color121: rgb(21, 141, 183);
$color121-light: #609ef1;
$color121-light-hover: #568ed8;
$color121-dark: #609ef1;
$color121-dark-border: #287be1;
$color121-hover: #0d5872;
$dark: #000;
$dark-blue-header: rgb(28, 31, 48);
$dark-blue: #181623;
$light: #fff;
$light-opacity: #fffffff0;
$size-body: calc(100vh - 80px);
$sucess-color: #28a745;
$danger-color: #b72025;
$gray-default: #c4c4c4;
$gray-dark: #4d4d4d;
$gray-dark-less: #7b7b7b;
$gray-light: #f1f1f1;
$button-linear-gradient: linear-gradient(to bottom, #606c88 0%, #3f4c6b 100%);
