@import "../../Sass/main";

.boxNotAnswer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px - 56px);
  justify-content: center;
  width: 100%;

  p {
    color: $text1;
    text-align: center;
    font-family: "Montserrat-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }

  .boxNotAnswerImg {
    height: 112px;
    width: 112px;
  }
}

.chartsContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 24px 16px;
  width: 100%;
}
