@import "../../Sass/main";
@import "bootstrap/scss/bootstrap";

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: black !important;
}

.compFieldCard {
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  .divEdit {
    display: flex;
    align-items: center;

    .inputLink {
      align-items: center;
      background: transparent;
      border: none;
      border-radius: 4px;
      color: $text0;
      display: inline-flex;
      gap: 8px;
      padding: 6px 12px;
      text-align: left;
      max-width: 250px;

      &.active {
        border: 1px solid $background-4;
        max-width: 157px;
      }

      &:focus {
        border: 1px solid $background-4;
        outline: none;
      }

      &:active {
        border: 1px solid $Standard_Default;
        outline: none;
      }
    }

    .icons {
      display: flex;
      padding: 0px 16px;
      align-items: center;
      gap: 16px;
      flex: 1 0 0;

      .icon {
        cursor: pointer;
        height: 18px;
        width: 18px;

        path {
          fill: $background-2;
        }
      }
    }
  }

}
