@import "../../Sass/main";

.CompWelcome {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100vh;
  justify-content: space-between;
  padding: 24px 16px;

  .header {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;

    .lettering {
      color: $Standard_Default;
      font-family: "Montserrat-Regular";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 42px;
    }

    .termos {
      color: $Standard_Default;
      cursor: pointer;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      text-decoration-line: underline;
    }
  }

  .content {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;

    .carouselPage {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      width: 358px;

      .caroselImg {
        height: 180px;
        width: 180px;
      }

      p {
        color: $text3;
        font-family: "Montserrat-Regular";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 34px;
        text-align: center;

        span {
          color: $Standard_Default;
        }
      }
    }
  }

  .footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 358px;

    button {
      width: 100%;
    }
  }
}
