@import "../../Sass/main.scss";

.compChartComment {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;

  .notComment {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;

    .iconComment {
      height: 64px;
      width: 64px;

      path {
        fill: $Standard_Default;
      }
    }

    p {
      color: $text1;
      text-align: center;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
    }
  }

  .commentContainer {
    width: 100%;

    .commentArea {
      width: 100%;

      .ulComment {
        margin: 0;
        overflow: hidden;
        width: 100%;

        .li-comment {
          align-items: center;
          align-self: stretch;
          background: $background-5;
          border-radius: 4px;
          color: $text3;
          display: flex;
          font-family: "Montserrat-Regular";
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          gap: 16px;
          line-height: 16px; /* 160% */
          padding: 12px 10px;

          span {
            font-size: 10px;
          }

          .btnsComment {
            display: flex;
            gap: 8px;

            .iconComent {
              cursor: pointer;
              width: 16px;

              path {
                fill: $text1;
              }
            }
          }

          & button {
            background: $gray-default;
            border: none;
            border-radius: 5px;
            display: flex;
            margin: 0 2px;
            padding: 5px;
          }

          .date {
            color: $gray-dark-less;
            font-size: 10px;
            width: fit-content;
          }

          .text {
            flex: 1;
          }
        }
      }
    }
  }
  .inputComment {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 10px;
    padding: 8px 0px;

    .iconSendComment {
      height: 16px;
      width: 16px;

      path {
        fill: $text-5;
      }
    }
  }
}

// .hide-comments {
//   background: #fff;
//   border-radius: 5px;
//   border: none;
//   color: $color121-light;
//   margin: 0 auto;
//   text-decoration: underline;
//   width: 90%;
// }

// .comment-container {
//   align-self: stretch;
//   background: $gray-light;
//   border-radius: 5px;
//   margin: 10px auto;
//   width: 90%;

//   .comment-area {
//     display: flex;
//     flex-direction: column;
//     padding: 10px;
//     width: 100%;

//     & > h4 {
//       font-size: 16px;
//       letter-spacing: 1px;
//       padding: 0 5px;
//     }

//     .textarea {
//       align-items: center;
//       border: 1px solid $gray-default;
//       border-radius: 5px;
//       display: inline-flex;
//       justify-content: center;
//       min-height: 50px;
//       max-height: 80px;

//       textarea {
//         align-content: center;
//         background: none;
//         border: none;
//         display: flex;
//         flex-grow: 1;
//         font-size: 14px;
//         flex-wrap: wrap;
//         height: 100%;
//         outline: none;
//         padding: 3px;
//         resize: none;
//       }

//       button {
//         background: $color121-light;
//         border: none;
//         border-radius: 4px;
//         color: #fff;
//         font-weight: 500;
//         font-size: 14px;
//         margin-right: 5px;
//         padding: 5px 10px;
//       }
//     }
//   }

//   .ul-comment {
//     margin: 0;
//     overflow: hidden;
//     padding: 0 10px;
//     width: 100%;

//     .li-comment {
//       align-items: center;
//       border-bottom: 1px solid #fff;
//       display: flex;
//       gap: 5px;
//       padding: 10px 0;

//       & > span {
//         font-size: 14px;
//         overflow-x: auto;
//       }

//       .btn-li {
//         display: flex;
//         img {
//           width: 20px;
//         }
//       }

//       & button {
//         background: $gray-default;
//         border: none;
//         border-radius: 5px;
//         display: flex;
//         margin: 0 2px;
//         padding: 5px;
//       }

//       .date {
//         color: $gray-dark-less;
//         font-size: 10px;
//         width: fit-content;
//       }

//       .text {
//         flex: 1;
//       }
//     }
//   }
// }
