@import "../../Sass/main.scss";

.compBottomTabHome {
  align-items: center;
  background: $text-5;
  bottom: 0;
  border-top: 1px solid #ebebeb;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 16px;
  position: fixed;
  width: 100%;

  .icon {
    height: 16px;
    margin-right: 10px;
    width: 16px;

    path {
      fill: $text-5;
    }
  }

  img {
    height: 60px;
    width: 52px;
  }

  .textArea {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: space-between;
    width: 148px;

    .date {
      color: $background1;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .interactions {
      color: $background1;
      font-family: "Montserrat-Regular";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;

      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
