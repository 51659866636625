@import "../../Sass/main";

.compCardTemplate {
  align-items: center;
  background-color: $text-5;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  color:  $text3;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-style: normal;
  font-weight: 600;
  justify-content: center;
  width: 160px;

  span {
    margin: 0;
  }

  .header {
    align-items: center;
    align-self: stretch;
    background-repeat: no-repeat;
    border-radius: 6px 6px 0 0;
    display: flex;
    gap: 16px;
    height: 112px;
    justify-content: center;
    padding: 32px;

    .button {
      display: none;
    }
  }

  .line {
    background-color: $background-4;
    height: 1px;
    width: 160px;
  }

  .footerCard {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;

    span {
      font-size: 14px;
      line-height: 24px;
    }
  }

  &:hover {
    border: 2px solid $Standard_Default;

    .header .button {
      display: block;
    }

    .line {
      width: 156px;
    }
  }
}

@media (max-width: 768px) {
  .compCardTemplate {
    .footerCard {
      span {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}
