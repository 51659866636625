@import "../../../Sass/main";

@media (max-width: 450px) {
  .compModalAdvancedLinks {
    width: 358px;
  }
}

@media (min-width: 451px) {
  .compModalAdvancedLinks {
    width: 450px;
  }
}

.alert {
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.compModalEditAdvancedLink {
  background-color: $text-5;
  border-top: 6px solid $Standard_Default;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 24px 32px 24px;
  position: relative;

  h2 {
    color: $text3;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 24px;
  }

  .urlArea {
    align-items: flex-end;
    align-self: stretch;
    display: flex;

    p {
      color: $text3;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
      padding: 10px 12px;
      text-align: center;
    }
  }

  .buttonArea {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
}
