@import "../../Sass/main";

.compCardOverview {
  align-items: center;
  background-color: $text-5;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-style: normal;
  gap: 16px;
  justify-content: center;
  max-width: 560px;
  padding: 12px 16px;
  width: 100%;

  span {
    margin: 0;
  }

  .title {
    align-self: stretch;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
  }

  .header {
    background-color: $text-5;

    span {
      color: $text1;
    }
  }

  .content {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .question {
      background-color: $background-5;

      .qtd,
      .percent {
        text-align: center;
      }

      span {
        color: $text3;
        flex: 1 1 0px;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .header,
  .content .question {
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 12px 10px;
  }

  .header span,
  .content .question span {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .compCardOverview {
    width: 100%;

    .title {
      font-size: 16px;
      line-height: 28px;
    }

    .header span,
    .content .question span {
      font-size: 10px;
      line-height: 16px;
    }
  }
}
