@import "../../Sass/main";

.compSwitchButton {
  label, span {
    margin: 0;
  }

  .switch {
    display: inline-block;
    height: auto;
    position: relative;
    width: 40px;
  }

  .switch input {
    height: 0;
    opacity: 0;
    width: 0;
  }

  .slider {
    cursor: pointer;
    background-color: $background-4;
    border-radius: 12px;
    bottom: 0;
    left: 0;
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
    -webkit-transition: .4s;

    &::before {
      content: "";
      background-color: $text-5;
      border-radius: 12px;
      bottom: 4px;
      height: 16px;
      left: 4px;
      position: absolute;
      transition: .4s;
      width: 16px;
      -webkit-transition: .4s;
    }

    &.disabled {
      cursor: default;
    }
  }

  input:checked + .slider {
    background-color: $Standard_Default;

    &::before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
    }
  }
}
