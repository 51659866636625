@import "../../Sass/main";

.pageShare {
  overflow: hidden;
  position: relative;

  span {
    margin: 0;
  }

  main {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: "Montserrat-Regular";
    font-style: normal;
    gap: 16px;
    padding: 0 16px 24px 16px;

    .wrapperInfo {
      align-items: center;
      display: flex;
      height: 160px;
      justify-content: space-between;
      overflow: hidden;
      position: relative;
      width: 390px;

      .infoProject {
        display: flex;
        flex-direction: column;

        .projectName {
          color: $text3;
          font-size: 24px;
          font-weight: 700;
          line-height: 42px;
          width: 300px;
        }

        .date {
          color: $text0;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
        }
      }

      .boxImg {
        .hazo {
          bottom: 10px;
          flex-shrink: 0;
          height: 164px;
          left: 275px;
          position: absolute;
          transform: rotate(-32.967deg);
          width: 164px;
        }
      }
    }
  }
}
