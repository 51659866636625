@import "../../Sass/main";

.compBotmaker {
  align-items: center;
  background: $text-5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  .messagesArea {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    gap: 20px;
    min-height: 100vh;
    margin-bottom: 61px;
    padding: 24px 16px;
    max-width: 600px;
    width: 100%;
  }
}
