@import "../../../Sass/main.scss";

@media (max-width: 450px) {
  .compModalQRCode {
    width: 358px;
  }
}

@media (min-width: 451px) {
  .compModalQRCode {
    width: 450px;
  }
}

.wrapper {
  z-index: 999;
}

.compModalQRCode {
  background-color: $text-5;
  border-top: 6px solid $Standard_Default;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0px 24px 32px 24px;
  position: relative;

  h2 {
    color: $text3;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-top: 24px;
  }

  p {
    margin: 0;
  }

  .qrArea {
    align-items: center;
    display: flex;
    justify-content: center;

    #printQr {
      padding: 5px;
    }
  }

  .infoArea {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;

    p {
      color: $Dark_0;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }

    .infoIcon {
      height: 18px;
      width: 18px;

      path {
        fill: $Dark_0;
      }
    }
  }

  .buttonArea {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 16px;
    justify-content: flex-end;
  }
}
