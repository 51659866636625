@import "../../../Sass/main";

.compModalQuestions {
  background-color: $text-5;
  border-radius: 16px;
  border-top: 6px solid $Standard_Default;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-style: normal;
  gap: 24px;
  padding: 0px 24px 32px 24px;
  width: 358px;

  span {
    margin: 0;
  }

  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 24px;

    .title {
      color: $text3;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
    }

    .icon {
      cursor: pointer;
      height: 24px;
      width: 24px;

      path {
        fill: $background-2;
      }
    }
  }

  .options {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0;

    .option {
      align-items: center;
      align-self: stretch;
      background: none;
      border: none;
      color: $text1;
      display: flex;
      font-size: 12px;
      font-weight: 700;
      gap: 12px;
      line-height: 20px;
      padding: 12px;

      .optionIcon svg {
        height: 18px;
        width: 18px;

        path {
          fill: $background-2;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .compModalQuestions {
    width: 450px;
  }
}

@media (min-width: 768px) {
  .compModalQuestions {

    header {
      .title {
        font-size: 18px;
        line-height: 32px;
      }
    }

    .options {
      .option {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
