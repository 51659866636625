@import "../../Sass/main";

.CompSignIn {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 100vh;
  justify-content: center;
  margin: 0 auto;
  padding: 24px 16px;
  width: 390px;

  .header {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    .chatText {
      align-self: center;
      align-items: center;
      background: $text-5;
      border: 1px solid $background-4;
      border-radius: 16px 0px 16px 16px;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
      color: $text0;
      display: flex;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      gap: 10px;
      grid-area: 1 / 1 / 2 / 2;
      justify-self: flex-end;
      justify-content: center;
      line-height: 24px;
      padding: 16px;
      width: 237px;
    }

    .hazoArea {
      grid-area: 1 / 2 / 2 / 3;
      .hazoImage {
        width: 100%;
      }
    }
  }

  .content {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 16px;

    button {
      width: 100%;
    }

    .connecteArea {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      .inputArea {
        color: $text3;
        display: flex;
        font-family: "Montserrat-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        gap: 8px;
        justify-content: row;
        line-height: 20px;

        input {
          cursor: pointer;
        }

        label {
          margin: 0;
        }
      }

      .forgotPassword {
        color: $Standard_Default;
        cursor: pointer;
        font-family: "Montserrat-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        text-align: right;
        text-decoration-line: underline;
      }
    }
  }

  .footer {
    font-family: "Montserrat-Regular";
    font-size: 14px;
    font-style: normal;
    line-height: 24px;

    p {
      margin: 0;
      text-align: center;
    }

    .firstText {
      color: $text3;
      text-align: center;
      font-weight: 400;
    }

    .secondText {
      color: $Standard_Default;
      cursor: pointer;
      font-weight: 700;
      text-decoration-line: underline;
    }
  }
}
