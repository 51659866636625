@import "../../Sass/main";

.compChartTable {
  width: 100%;

  .table {
    border-collapse: separate;
    border-spacing: 0 10px;

    thead {
      .cell {
        align-items: center;
        color: $text1;
        display: flex;
        font-family: "Montserrat-Regular";
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        gap: 8px;
        line-height: 16px;

        .ordenation {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          gap: 2px;
          justify-content: center;

          .ordenationIcon {
            cursor: pointer;
            height: 10px;
            width: 10px;
          }
        }
      }
    }

    td {
      border: none;
      padding: 12px 10px;
    }

    tbody {
      color: $text3;
      font-family: "Montserrat-Regular";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      tr {
        background: $background-5;

        td:first-child {
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }

        td:last-child {
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
    }
  }
}
