@import "../../Sass/main";

.compDraft {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  position: relative;

  &.styleVisible {
    animation: slideInAnimation 600ms linear forwards;
  }

  .box {
    align-items: center;
    background-color: $text-5;
    border: 1px solid $background-4;
    border-radius: 16px 0px 16px 16px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    font-family: "Montserrat-Regular";
    font-style: normal;
    gap: 10px;
    justify-content: center;
    padding: 16px;
    width: 237px;

    span {
      color: $text0;
      flex: 1 0 0;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
    }
  }

  .boxImg {
    .hazo {
      flex-shrink: 0;
      height: 164px;
      transform: rotate(-32.967deg);
      width: 164px;
    }
  }
}

@keyframes slideInAnimation {
  0% {
    transform: translateX(100%);
  }
  70%, 100% {
    transform: translateX(0);
  }
}
