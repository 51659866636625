@import "../../Sass/main";

.headerUpdates {
  height: 103px;
  margin: 0 auto;
  width: 390px;
}

.containerUpdates {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  margin: 0 auto;
  padding: 16px;
  width: 390px;

  .versionUpdate{
    display: grid;
    grid-template-columns: 0fr 1fr 2fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    position: relative;
    width: 100%;

    .iconUpload {
      path{fill: $Standard_Default};
      height: 16px;
      left: 11px;
      position: absolute;
      top: 9px;
      width: 16px;
    }

    .textUpdate {
      align-self: center;
      color: var(--text-3, $text3);
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin: 0;
      text-align: center;
    }

    .dateUpdate{
      align-self: center;
      color: var(--text-1, $text1);
      font-family: "Montserrat-Regular";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      justify-self: end;
      line-height: 16px;
      text-align: center;
    }
  }

  .contentUpdate{
    display: flex;
    align-items: center;
    gap: 12px;

    .iconFeature {
      path{fill: $Success_Standard_Default};
      height: 18px;
      width: 18px;
    }

    .iconBug {
      path{fill: $Error_Standard_Default};
      height: 18px;
      width: 18px;
    }

    .textContent{
      color: var(--text-2, $text2);
      font-family: "Montserrat-regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      height: 100%;
      line-height: 20px;
      margin: 0;
      text-align: left;
      width: 100%;
    }

  }

  hr {
    background: #F2F2F2;
    height: 1px;
    width: 390px;
  }
}
