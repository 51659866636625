@import "../../Sass/main";

.CompForgotPassword {
  align-items: center;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  margin: 0 auto;
  min-height: 100vh;
  padding-top: 24px;
  width: 390px;

  header {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;

    h1 {
      color: $background0;
      font-family: "Montserrat-Regular";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 42px;
    }

    p {
      color: $text1;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .content {
    width: 100%;
  }

  footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    button {
      width: 100%;
    }
  }
}
