@import "../../Sass/main";

.projectsComp {
  .text {
    color: $text1;
    white-space: pre;
    margin: 0;
    font-family: "Montserrat-Regular";
    font-style: normal;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  .iconProject {
    width: 112px;
    height: 112px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 80vh;
  }

  .alert {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .projects {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    padding: 12px 16px 24px 16px;
    margin-top: 120px;

    &.blank {
      height: 80vh;
      flex-shrink: 0;
    }
  }

  .footer {
    margin-top: 100px;
  }
}
