@import "../../../Sass/main.scss";

.compChat {
  align-items: center;
  background-color: $Standard_Default;
  display: flex;
  height: 56px;
  gap: 12px;
  justify-content: space-between;
  padding: 6px 16px;
  width: 100%;

  .left {
    align-items: center;
    cursor: pointer;
    display: flex;

    .icon {
      height: 48px;
      width: 48px;
    }
  }

  .middle {
    color: $Standard_Default;
    display: flex;
    flex-direction: column;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    width: 100%;

    .description {
      color: $text-5;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
    }

    .title {
      color: $text-5;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 0;
    }
  }

  .right {
    align-items: center;
    display: flex;
    gap: 32px;
    justify-content: flex-end;

    .icon {
      cursor: pointer;
      height: 18px;
      width: 18px;
      path {
        fill: $text-5;
      }
    }
  }
}
