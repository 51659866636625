@import "../../../Sass/main";

.compModalCropImage {
  align-items: center;
  background-color: $text-5;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100vw;
  z-index: 1051;

  .crop-container {
    bottom: 0;
    height: calc(100vh - 100px);
    left: 0;
    position: absolute;
    right: 0;
    bottom: 100px;
  }

  .controls {
    align-items: center;
    background-color: #fff;
    bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: center;
    left: 0;
    width: 100%;
    position: absolute;

    .buttonArea {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: center;
      margin-top: 20px;

      button {
        width: 156px;
      }
    }
  }
}
