@import "../../Sass/main.scss";

.successAlerts {
  border-left: 6px solid $Success_Standard_Default;
}

.infoAlerts {
  border-left: 6px solid $Info_Standard_Hover;
}

.errorAlerts {
  border-left: 6px solid $Error_Standard_Hover;
}

.warningAlerts {
  border-left: 6px solid $Warning_Dark_0;
}

.compAlerts {
  align-items: center;
  background: var(--text-5, #fff);
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: 1fr;
  gap: 16px;
  padding: 16px;
  width: 358px;

  span {
    grid-area: 1 / 2 / 2 / 3;
    font-family: "Montserrat-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .success {
    grid-area: 1 / 1 / 2 / 2;
    path {
      fill: $Success_Standard_Default;
    }
  }

  .info {
    grid-area: 1 / 1 / 2 / 2;
    path {
      fill: $Info_Standard_Hover;
    }
  }

  .error {
    grid-area: 1 / 1 / 2 / 2;
    path {
      fill: $Error_Standard_Hover;
    }
  }

  .warning {
    grid-area: 1 / 1 / 2 / 2;
    path {
      fill: $Warning_Dark_0;
    }
  }

  .close {
    cursor: pointer;
    grid-area: 1 / 3 / 2 / 4;
    height: 18px;
    justify-self: flex-end;
    width: 18px;
  }
}
