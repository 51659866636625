@import "../../Sass/main.scss";

.defaultInput {
  border: 1px solid $text-3;
}

.focusInput {
  border: 1px solid $Standard_Default;
}

.afterInput {
  border: 1px solid $text-3;
}

.errorInput {
  border: 1px solid $Error_Standard_Default;
}

.successInput {
  border: 1px solid $text-3;
}

.compInput {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  label {
    color: $text0;
    font-family: "Montserrat-regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }

  .inputArea {
    align-items: center;
    border-radius: 12px;
    display: flex;
    gap: 8px;
    padding: 10px 12px;
    width: 100%;

    input,
    input:focus,
    input:focus-visible {
      border: none;
      color: $text1;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      outline: none;
      width: 100%;
    }

    .eyeIcon {
      cursor: pointer;
      height: 20px;
      width: 20px;
    }

    .checkIcon {
      cursor: pointer;
      height: 20px;
      width: 20px;
      path {
        fill: $Success_Standard_Default;
      }
    }
  }
}
