@import "../../Sass/main";

.compChartDefault {
  align-items: center;
  background: $text-5;
  border-radius: 8px;
  border: 1px solid $text-3;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 560px;
  padding: 16px;
  width: 100%;

  .chartArea {
    margin: 20px 0;
    min-height: 350px;
    width: 100%;
  }

  .overviewArea {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 2px;
    justify-content: flex-start;

    p {
      color: $text0;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin: 0;

      span {
        font-weight: 700;
      }
    }
  }

  .buttonArea {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;

    span {
      color: $gray2;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .titleArea {
    align-items: flex-start;
    color: $text3;
    display: flex;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    gap: 8px;
    padding: 6px 4px;
    line-height: 28px;
  }

  .toggleArea {
    align-items: center;
    align-self: stretch;
    color: $text-1;
    display: flex;
    font-family: "Montserrat-Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    justify-content: space-between;
    line-height: 20px;

    .questionType {
      align-items: flex-start;
      display: flex;
      gap: 8px;
      padding: 6px 4px;
    }

    .menuArea {
      align-items: center;
      display: flex;
      gap: 12px;
      justify-content: center;
      padding: 10px 20px;

      .menuIcon {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 16px;
        justify-content: center;
        padding: 2.435px 0px;
        width: 16px;
      }
    }
  }
}
