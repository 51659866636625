@import "../../Sass/main";

$font-large: 16px;
$font-medium: 14px;
$font-small: 12px;

.compCardMyLink {
  align-items: flex-start;
  background: transparent;
  border: 1px solid $background-4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-style: normal;
  gap: 16px;
  padding: 12px 16px;
  width: 358px;

  span {
    margin: 0;
  }

  .header {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;

    .title {
      color: $text3;
      font-size: $font-large;
      font-weight: 600;
      line-height: 28px;
    }

    .toogle {
      align-items: center;
      display: flex;
      gap: 8px;

      span {
        color: $gray2;
      }
    }
  }

  .link {
    align-items: center;
    align-self: stretch;
    display: flex;

    span {
      color: $gray2;
    }
  }

  .description {
    color: $text0;
  }

  .footer {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;

    .infoText {
      color: $text3;
    }
  }

  .toogle span, .link span, .link .inputLink, .description, .footer .infoText {
    font-size: $font-medium;
    font-weight: 400;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .compCardMyLink {
    .header {
      .title {
        font-size: $font-medium;
      }
    }

    .toogle span, .link span, .description, .footer .infoText {
      font-size: $font-small;
      line-height: 20px;
    }
  }
}
