@import "../../../Sass/main.scss";

.compSearch {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  height: 56px;
  gap: 15px;
  justify-content: space-between;
  padding: 6px 16px;

  .left {
    align-items: center;
    cursor: pointer;
    display: flex;
    width: 100%;

    .icon {
      height: 20px;
      width: 20px;
      path {
        fill: $text0;
      }
    }
  }

  .secondIcon {
    cursor: pointer;
    position: absolute;
    right: 90px;

    .iconClose {
      height: 20px;
      width: 20px;
      path {
        fill: $text3;
      }
    }
  }

  .right {
    align-items: center;
    display: flex;
    gap: 32px;
    justify-content: flex-end;

    span {
      color: $Standard_Default;
      cursor: pointer;
      text-align: center;
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      text-decoration-line: underline;
    }
  }
}
