@import "../../Sass/main";

.compCardBenefits {
  align-items: flex-start;
  background-color: $light;
  border-radius: 16px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 24px;
  // width: 386px;

  span {
    margin: 0;
  }

  .header {
    width: 100%;

    .title {
      color: $text3;
    }
  }

  .content {
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 1px solid $background-5;
    border-top: 1px solid $background-5;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .benefit {
      align-items: center;
      display: flex;
      gap: 8px;

      .iconCheck {
        height: 16px;
        width: 16px;
        flex-shrink: 0;

        path {
          fill: $Success_Dark_1;
        }
      }

      .text {
        color: $gray3;
      }
    }

  }

  .footer {
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    .text {
      color: $Standard_Default;
    }

    .iconArrow {
      height: 18px;
      width: 18px;

      path {
        fill: $Standard_Default;
      }
    }
  }

  .header, .content, .footer {
    padding: 16px;
  }

  .content .description, .content, .footer {
    font-weight: 400;
  }
}
