@import "../../../Sass/main.scss";

.compOptionConfiguration {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 11px;
  padding: 0px 2px;
  width: 100%;

  .clearInputBackground {
    input {
      background-color: rgba($color: #000000, $alpha: 0);
    }
  }

  .elipse {
    background-color: $text3;
    border-radius: 50%;
    height: 6px;
    min-width: 6px;
  }

  .inputArea {
    padding: 6px 12px !important;
  }

  .iconOptionConfiguration {
    cursor: pointer;
    min-width: 18px;

    path {
      fill: $background-2;
    }
  }

  .showColor {
    border-radius: 2px;
    cursor: pointer;
    height: 18px;
    width: 32.4px;

    input {
      visibility: hidden;
    }
  }
}
