@import "../../../Sass/main";

.compInsertImage {
  input {
    background-color: rgba($color: #000000, $alpha: 0);
  }

  .warning {
    span {
      font-weight: 700;
    }

    color: $Standard_Default;
    font-family: "Montserrat-Regular";
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    margin-top: 10px;
  }
}
