.genericModal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  height: 100vh;
  min-width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999999;

  .closeIcon {
    cursor: pointer;
    height: 24px;
    position: absolute;
    right: 24px;
    top: 28px;
    width: 24px;
  }
}
