@import "../../Sass/main";

.CompSignUp {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  margin: 0 auto;
  min-height: 100vh;
  padding: 24px 16px;
  width: 390px;

  .header {
    width: 100%;
    h1 {
      color: $background0;
      font-family: "Montserrat-Regular";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 42px;
    }

    p {
      color: $text1;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .content {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .icon {
      height: 20px;
      width: 20px;

      path {
        fill: $Success_Standard_Default;
      }
    }

    .term {
      align-items: center;
      align-self: stretch;
      display: flex;
      gap: 8px;

      label {
        color: $text3;
        font-family: "Montserrat-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;

        span {
          color: $Standard_Default;
          cursor: pointer;
          font-weight: 700;
          text-decoration-line: underline;
        }
      }
    }
  }

  .footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    button {
      width: 100%;
    }
  }
}
