@import "../../Sass/main";

.headerChangePassword {
  height: 103px;
  margin: 0 auto;
  width: 390px;
}

.containerMainChangePassword {
  display: flex;
  width: 390px;
  height: 608px;
  padding: 24px 16px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  margin: 0 auto;

  .formMainChangePassword {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    .alertProfile {
      position: absolute;
      justify-content: center;
      top: 10px;
    }

    .textChangePassword {
      align-self: stretch;
      color: var(--text-1, $text1);
      font-family: "Montserrat-regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
    }

    .inputsFormChangePassword {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
