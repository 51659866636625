.carousel-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 73px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  .carousel {
    align-items: center;
    display: flex;
    height: 288px;
    justify-content: center;
    width: 100%;
  }

  .dots {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;

    .dot {
      width: 8px;
      height: 8px;
      margin: 0 4px;
      background-color: #ccc;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .dot.active {
    background-color: #333;
    display: block;
  }
}
