@import "../../Sass/main";

.compNotification {
  align-items: center;
  display: flex;
  font-family: "Montserrat-Regular";
  font-style: normal;
  gap: 53px;
  padding: 32px 16px;

  span {
    margin: 0;
  }

  &.read {
    background-color: $text-5;
  }

  &.unread {
    background-color: $Info_Light_0;
  }

  .contentNotification {
    align-items: center;
    display: flex;
    flex: 1 0 0;
    gap: 8px;

    .dot {
      background-color: $Standard_Default;
      border-radius: 10px;
      height: 8px;
      width: 8px;
    }

    .textNotification {
      align-items: flex-start;
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      gap: 12px;

      .title, .message {
        color: $gray2;
      }

      .title, .message, .link {
        font-size: 14px;
        line-height: 24px;
      }

      .divText {
        align-items: flex-start;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .title {
        font-weight: 700;
      }

      .message {
        font-weight: 400;
      }

      .link {
        background: none;
        border: none;
        color: $Standard_Default;
        font-weight: 700;
        padding: 0;
        text-decoration: underline;
      }

      .date {
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  .divIcon {
    background: transparent;
    border: none;

    .icon {
      flex-shrink: 0;
      height: 20px;
      width: 20px;

      path {
        fill: $background-2;
      }

      &.saved {
        path {
          fill: $Standard_Default;
        }
      }
    }
  }
}
