@import "../../../Sass/main.scss";

.compLikeDislike {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px;
  justify-content: center;
  width: 100%;

  .optionConfigurationArea {
    align-items: center;
    align-self: stretch;
    color: $text2;
    display: flex;
    font-family: "Montserrat-Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    gap: 8px;
    line-height: 20px;
    padding: 0px 2px;

    .elipse {
      background-color: $text3;
      border-radius: 50%;
      height: 6px;
      min-width: 6px;
    }
  }
}
