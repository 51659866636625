@import "../../Sass/main";

.compReports {
  height: 100px;
  margin-top: 56px;
  min-height: 100vh;

  .reportNavigation {
    align-items: center;
    display: flex;
    gap: 6px;
    padding: 12px 16px;
    justify-content: center;
    width: 100%;
  }
}
