@import "../../Sass/main";

$size-large: 20px;
$size-medium: 16px;
$size-small: 12px;

$font-large: 16px;
$font-medium: 14px;
$font-small: 12px;

.button {
  align-items: center;
  border: none;
  display: flex;
  gap: 12px;
  justify-content: center;

  &.primary {
    background-color: $Standard_Default;
  }

  &.cancel {
    background-color: $Error_Standard_Default;
  }

  &.success {
    background-color: $Success_Standard_Default;
  }

  &.disable {
    background-color:$background-4;
    cursor: default;
  }

  &.large {
    border-radius: 16px;
    line-height: 28px;
    padding: 14px 20px;
  }

  &.medium {
    border-radius: 12px;
    line-height: 24px;
    padding: 10px 20px;
  }

  &.small {
    border-radius: 8px;
    line-height: 20px;
    padding: 6px 20px;
  }

  &.auxiliary {
    &.primary {
      background-color: $Light_0;

      .buttonText {
        color: $Standard_Default;
      }

      .icon {
        path {
          fill: $Standard_Default;
        }
      }
    }

    &:hover {
      &.primary {
        background-color: $Light_1;
      }
    }
  }

  &.default {
    &:hover {
      &.primary {
        background-color: $Standard_Hover;
      }

      &.cancel {
        background-color: $Error_Standard_Hover;
      }

      &.success {
        background-color: $Success_Standard_Hover;
      }
    }
  }

  &.outline {
    &.primary {
      background-color: $light;
      border: 1px solid $Standard_Default;

      .buttonText {
        color: $Standard_Default;
      }

      .icon {
        path {
          fill: $Standard_Default;
        }
      }
    }

    &.cancel {
      background-color: $light;
      border: 1px solid $Error_Standard_Default;

      .buttonText {
        color: $Error_Standard_Default;
      }

      .icon {
        path {
          fill: $Error_Standard_Default;
        }
      }
    }

    &.disable {
      background: none;
      border: 1px solid $text-3;
      cursor: default;

      .buttonText {
        color: $text-3;
      }

      .icon {
        path {
          fill: $text-3;
        }
      }
    }

    &:hover {
      &.primary {
        background-color: $Light_0;
      }

      &.cancel {
        background-color: $Error_Light_0;
      }
    }
  }

  &.text {
    &.primary {
      background: none;

      .buttonText {
        color: $Standard_Default;
      }

      .icon {
        path {
          fill: $Standard_Default;
        }
      }
    }

    &:hover {
      &.primary {
        background-color: $Light_0;
      }
    }
  }
}

.buttonText {
  color: $light;
  font-style: normal;
  font-weight: 600;

  &.large {
    font-size: $font-large;
  }

  &.medium {
    font-size: $font-medium;
  }

  &.small {
    font-size: $font-small;
  }

  &.primary {
    color: $Standard_Default;

    .button {
      background: none;
    }

    .icon {
      path {
        fill: $Standard_Default;
      }
    }
  }

  &:hover {
    &.primary {
      background-color: $Light_0;
    }
  }
}

.icon {
  path {
    fill: $light;
  }

  &.large svg {
    width: $size-large;
  }

  &.medium svg {
    width: $size-medium;
  }

  &.small svg {
    width: $size-small;
  }
}
