@import "../../Sass/main.scss";

.compProgressBar {
  width: 100%;
  height: 4px;
  background-color: $background-4;
  border-radius: 10px;
  overflow: hidden;

  .progressBarFill {
    height: 100%;
    background-color: $Success_Standard_Default;
    transition: width 0.3s ease;
  }
}
