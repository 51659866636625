@import "../../Sass/main.scss";

.compCardProjects {
  cursor: pointer;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  gap: 12px;
  padding: 16px 12px 10px 12px;
  width: 358px;

  .titleArea {
    display: flex;
    flex-direction: column;

    .titleAlign {
      display: flex;
      gap: 8px;

      h2 {
        color: $text3;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
      }

      .buttonNewAnswerArea {
        flex-grow: 1;

        .newAnswers {
          background-color: $Light_0;
          border-radius: 12px;
          color: $Dark_0;
          font-size: 8px;
          font-weight: 800;
          height: 28px;
          line-height: 16px;
          padding: 6px;
          width: 81px;
        }
      }

      .star {
        cursor: pointer;
        height: 20px;
        width: 20px;
      }

      .yellowStar {
        cursor: pointer;
        height: 20px;
        width: 20px;

        path {
          fill: $Warning_Dark_0;
        }
      }
    }

    .active {
      color: $Success_Dark_0;
    }

    .createdAt {
      color: $text2;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    .inactive {
      color: $Error_Standard_Hover;
    }

    .spanStatus {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
    }
  }

  .detailingArea {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-around;

    .field {
      align-items: center;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      justify-content: center;
      line-height: 20px;

      .fieldName {
        color: $text-1;
        font-weight: 400;
      }

      .fieldValue {
        color: $text0;
        font-weight: 700;
      }
    }
  }
}
