@import "../../Sass/main";

$font-large: 16px;
$font-medium: 14px;
$font-small: 12px;

.compCardSession {
  align-items: flex-start;
  background-color: $text-5;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-style: normal;
  gap: 12px;
  padding: 8px 12px;
  width: 358px;

  span {
    margin: 0;
  }

  .wrapperCard {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .header {
      align-items: center;
      display: flex;
      gap: 6px;

      .icon {
        path {
          fill: $text3;
        }
      }

      .title {
        color: $text3;
        font-size: $font-large;
        line-height: 28px;
      }
    }

    .description {
      color: $text0;
      font-weight: 400;
    }
  }

  .line {
    background: $gray5;
    height: 1px;
    width: 334px;
  }

  .footerCard {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: space-between;

    span {
      color: $Standard_Default;
    }

    .icon {
      path {
        fill: $Standard_Default;
      }
    }
  }

  .icon {
    height: 24px;
    width: 24px;
  }

  .footer span,
  .title {
    font-weight: 600;
  }

  .footer span,
  .description {
    font-size: $font-medium;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .compCardSession {
    .wrapper {
      .header {
        .title {
          font-size: $font-medium;
          line-height: 24px;
        }
      }
    }

    .footer span,
    .wrapper .description {
      font-size: $font-small;
      line-height: 20px;
    }
  }
}
