@import "../../Sass/main";

.compCardSigning {
  align-items: flex-start;
  background-color: $light;
  border-radius: 16px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 24px;
  // width: 386px;

  label, span {
    margin: 0;
  }

  .header {
    width: 100%;

    .title {
      color: $text3;
    }
  }

  .content {
    align-items: flex-start;
    align-self: stretch;
    border-bottom: 1px solid $background-5;
    border-top: 1px solid $background-5;
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  .footer {
    align-items: center;
    align-self: stretch;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    .icon {
      height: 18px;
      width: 18px;
    }

    &.free {
      .text {
        color: $Success_Dark_1;
      }

      .icon {
        path {
          fill: $Success_Dark_1;
        }
      }
    }

    &.default {
      .text {
        color: $Error_Standard_Default;
      }

      .icon {
        path {
          fill: $Error_Standard_Default;
        }
      }
    }
  }

  .header, .content, .footer {
    padding: 16px;
  }

  .content .description, .content .checkbox label, .footer .text {
    font-weight: 400;
  }
}
