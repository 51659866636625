@import "../../Sass/main";

.headerAccount {
  height: 103px;
  margin: 0 auto;
  width: 390px;
}

.containerMainAccount {
  align-items: flex-start;
  background: $text-5;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 1022px;
  margin: 0 auto;
  overflow-x: hidden;
  padding: 6px 16px;
  width: 390px;

  .alertMainAccount {
    position: absolute;
    justify-content: center;
    top: 10px;
    z-index: 3;
  }

  .containerTextAccount {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .statusAccount {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      gap: 16px;
      position: relative;

      .iconStatusAccount {
        path{fill: $Success_Standard_Default};
        height: 42px;
        width: 42px;
      }

      .h5ContainerAccount {
        color: var(--text-3, $text3);
        font-family: "Montserrat-Regular";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 175%;
        text-align: center;
      }

      .imgContainerAccount {
        position: absolute;
        right: -220px;
        transform: rotate(-35.459deg);
        top: -30px;
        width: 164px;
      }
    }

    .pContainerAccount {
      align-self: stretch;
      color: var(--text-0, $text0);
      font-family: "Montserrat-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin: 0;
      width: 260px;
    }
  }

  .containerAboutAccount {
    margin-top: 20px;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;

    .avatarAccount{
      width: 84px;
      height: 80px;
      position: relative;
      text-align: center;

      .h5ContainerAboutAccount {
        color: var(--primary-dark-0, #528BD3);
        font-family: "Montserrat-Regular";
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        left: 50%;
        line-height: 42px;
        position: absolute;
        top: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
      }

    }

    .pContainerAboutAccount {
      color: var(--text-3, #464646);
      font-family: "Montserrat-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin: 0;
      text-align: center;
      width: 100%;
    }

    .spanContainerAboutAccount {
      color: var(--text-1, #747474);
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }
  }

  .containerOptionsAccount {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    hr {
      background: #F2F2F2;
      height: 1px;
      width: 390px;
    }
  }

}
