@import "../../../Sass/main";

.alert {
  display: flex;
  justify-content: center;
  z-index: 1000;
}

.compModalConfigAdvancedLinks {
  background-color: $text-5;
  font-family: "Montserrat-Regular";
  font-style: normal;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  z-index: 2;

  span, p {
    margin: 0;
  }

  main {
    .noCards {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 90vh;
      justify-content: center;

      .img {
        height: 112px;
        width: 112px;
      }

      p {
        color: $text1;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        white-space: pre;
      }
    }

    .cards {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      padding: 24px 16px;
    }

    .bottom {
      margin-top: 50px;
    }
  }
}
