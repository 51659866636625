@import "../../../Sass/main.scss";

.compOpenAnswer {
  align-items: flex-start;
  align-self: stretch;
  color: $text3;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat-Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  gap: 16px;
  justify-content: center;
  line-height: 20px;

  hr {
    background: $background-5;
    height: 1px;
    margin: 0;
    width: 304px;
  }

  .optionLineOpenAnswer {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 0px 2px;

    input {
      border-radius: 4px;
      border: 1px solid $background-4;
    }
  }

  .PreferNotAnwser {
    align-items: center;
    display: flex;
    gap: 10px;
  }
}
