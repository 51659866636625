@import "../../../Sass/main";

.compMenuInteractive {
  width: 100%;

  .inputMenuInteractive {
    margin-bottom: 10px;

    input {
      background-color: rgba($color: #000000, $alpha: 0);
    }
  }

  .optionsMenuInteractive {
    align-items: center;
    display: flex;
    gap: 12px;
    margin-bottom: 10px;

    .optionMenuInteractive {
      align-items: center;
      align-self: stretch;
      color: $text3;
      display: flex;
      flex-direction: row;
      font-family: "Montserrat-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      gap: 8px;
      line-height: 20px;
      padding: 0px 2px;

      input {
        border-radius: 4px;
        border: 1px solid $background-4;
      }
    }
  }
}

// .compMenuInteractive {
//   color: $gray-dark;
//   display: flex;
//   flex-direction: row;
//   padding-left: calc(10% - 50px);

//   .questionArea {
//     width: calc(90% - 10px);
//   }

//   a {
//     text-decoration: none;
//   }

//   textarea {
//     @include input-hazo();
//     height: 84px;
//   }

//   input {
//     @include input-hazo();
//   }

//   .buttonSelectedType {
//     background-color: $color121-light;
//     border: none;
//     border-radius: 5px;
//     color: $light;
//     font-size: 14px;
//     font-weight: bold;
//     height: 43px;
//     min-width: 50px;
//     padding: 0 12px;

//     &:hover {
//       color: $light;
//     }
//   }

//   .buttonNotSelectedType {
//     border: 2px solid $gray-dark-less;
//     border-radius: 5px;
//     color: $gray-dark-less !important;
//     font-size: 14px;
//     font-weight: bold;
//     height: 43px;
//     min-width: 50px;
//     padding: 0 12px;

//     &:hover {
//       color: $light;
//     }
//   }

//   // .conditionsArea {
//   //   .option {
//   //     align-items: center;
//   //     background-color: #ffffff;
//   //     border-radius: 5px;
//   //     display: flex;
//   //     justify-content: center;
//   //     margin-right: 5px;
//   //     min-height: 43px;
//   //     min-width: 120px;
//   //     padding: 10px 10px;
//   //     word-break: break-all;
//   //   }
//   // }

//   .iconChange {
//     height: 15px;
//     width: 15px;
//   }

//   .lineOption {
//     display: flex;
//     flex-direction: column;
//     margin-top: 20px;

//     .optionsMenuInteractive {
//       align-items: center;
//       display: flex;
//       flex: 1;
//       flex-direction: row;
//       flex-wrap: wrap;
//       font-size: 14px;
//       gap: 10px;
//       margin-bottom: 10px;

//       .optionsInput {
//         border: none;
//         border-radius: 5px;
//         font-size: 14px;
//         height: auto;
//         min-width: 120px;
//         overflow: hidden;
//         padding: 10px 10px;
//         resize: none;
//         text-align: start;

//         &::placeholder {
//           color: $light;
//           opacity: 1;
//         }

//         &:-ms-input-placeholder {
//           color: $light;
//         }

//         &::-ms-input-placeholder {
//           color: $light;
//         }
//       }
//     }
//   }

//   .showOptionsAreaMenuInteractive {
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap;
//   }

//   .showOptionsButtonMenuInteractive {
//     align-items: center;
//     background-color: $buttonChatbotDefaultColor;
//     border: none;
//     border-radius: 5px;
//     color: $light;
//     display: flex;
//     height: auto;
//     justify-content: center;
//     margin-bottom: 10px;
//     padding: 13px 12px;
//     text-align: center;
//     width: fit-content;
//   }
// }
